var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "background-color": "#fff",
      width: "100%",
      height: "100%"
    }
  }, [_vm.validateFailed || _vm.showDialog ? _c("div", {
    staticClass: "init"
  }, [_vm._m(0), _vm.validateFailed ? _c("div", [_c("el-result", {
    attrs: {
      icon: "error",
      title: _vm.$t("user.email-vildate-result-title"),
      subTitle: _vm.subTitle
    }
  })], 1) : _vm._e(), _c("div", [_vm.showDialog ? _c("div", {
    staticClass: "form-card in-three a-fadeinB"
  }, [_c("p", {
    staticClass: "in-one"
  }, [_vm._v(_vm._s(_vm.$t("user.set-pwd")))]), _c("el-form", {
    ref: "modifyPwdForm",
    attrs: {
      model: _vm.pwdModify,
      rules: _vm.rules,
      "label-width": "auto"
    }
  }, [_c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("user.login-username"),
      prop: "email"
    }
  }, [_vm._v(" " + _vm._s(_vm.pwdModify.username) + " ")]), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.new-pwd"),
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.password,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "password", $$v);
      },
      expression: "pwdModify.password"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("person-setting.confirm-pwd"),
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "confirmPassword", $$v);
      },
      expression: "pwdModify.confirmPassword"
    }
  })], 1), _c("el-form-item", [_c("div", {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.$t("user.confirm-button")))])], 1)])], 1)], 1) : _vm._e()]), _c("BottomInfo")], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("div", [_c("img", {
    staticClass: "logo_login",
    attrs: {
      src: require("@/assets/logo_login.png"),
      alt: ""
    }
  })]), _c("div", [_c("span", {
    staticClass: "title"
  }, [_vm._v("Zenlayer DNS")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };